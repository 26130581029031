.aboutcontainer {
  min-height:100vh;
  max-height:100vh;
  width:auto;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  place-items: center;
}
.column-about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.aboutpic {
    width: 80%;
    margin-left: 7rem;
    margin-right: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutmessage{
  display: flex;
  flex-direction: column;

}

.heading {
  width: 30%;
  font-size: 1.15rem;
}
._img {
  width: 45em;
  height:40em;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}
.contact-btn {
  margin-right: 20px;
  text-decoration: none;
  color: #464646;
}
.contact-btn:hover {
  margin-right: 20px;
  text-decoration: none;
}
.contactMe {
  text-decoration: none;
  border: 2px solid black;
  border-radius: 3px;
  padding:2px;
  background-color: rgb(170, 223, 149);
  font-weight: bold;
  color:rgb(90, 0, 245);
}

@media (max-width: 1024px) {
  .about {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  ._img {
    margin: 2em;
  }
  ._content_wrapper {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .about {
    margin-left: 4em;
    margin-right: 4em;
    flex-direction: column;
  }
  ._img {
    width: 15em;
    height: 12em;
  }
  ._content_wrapper {
    width: 15em;
  }
}