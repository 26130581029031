* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}
::-moz-selection {
  background: #00bfbf;
  color: #fafafa;
  text-shadow: none;
}
::selection {
  background: #00bfbf;
  color: #fafafa;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.5s ease;
  background-image: linear-gradient( 0deg, rgb(255, 253, 239) 10%, rgb(168, 250, 247) 50%, rgb(168, 250, 247) 26.8%, rgb(234, 213, 255) 80%  );
  height:auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}