nav {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform: rotate(-90deg) translate3d(-50%,0,0);
  top: 15%;
  left: -6.5rem;
  margin-left:-15px;
}
nav h2 {
  font-size: 200%;
  text-decoration: none;
  margin-right: 20px;
  color: #575757;
  transition: all 0.2s ease-in-out;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  
}
nav h2:hover {
  margin-right: 10px;
  color: #4f4cec;
  font-size: 215%;
  text-decoration: underline;
  text-shadow: 0 0 10px rgba(226, 192, 21, 0.4);
}
nav a.active {
  color: #ec704c;
  text-decoration: underline;
}

.img {
  position:fixed;
  margin:10px;
  width: 75px;
  height: 75px;
  border-radius:60%;
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #7a47e1 50%,
    rgb(255, 179, 221) 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.img:before {
  content: '';
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
}
.img:hover {
  background-position: 0;
  cursor:url(https://cdn.custom-cursor.com/db/9896/32/cute-shiba-inu-cursor.png) , default!important;
}
 .img:hover::before {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .img {
    width: 50px;
    height: 50px;
  }
  
}